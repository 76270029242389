import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./src/components/layout";

const React = require("react")

// in gastby-browser.js
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
  }) => {
    window.scrollTo(0, 0)
    return false
  }
import * as React from 'react'
import { Link } from 'gatsby'

// Step 2: Define your component
export const Footer = ({containerClasses}) => {

  const currentYear = new Date().getFullYear().toString();

  return (
    <footer class="pt-2 border-top text-muted pad-safe-lr">
      <div class={containerClasses}>
        <div class="row">
          <div class="col-md-6 col-xl-4 py-1 ">
            <p className='text-uppercase text-black mb-2'><strong><small>Our Work</small></strong></p>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><Link to="/services" class="nav-link p-0 text-muted" activeClassName="nav-link p-0 text-muted">Services</Link></li>
              <li class="nav-item mb-2"><Link to="/experience" class="nav-link p-0 text-muted" activeClassName="nav-link p-0 text-muted">Experience</Link></li>
              <li class="nav-item mb-2"><Link to="/principles" class="nav-link p-0 text-muted" activeClassName="nav-link p-0 text-muted">Principles</Link></li>
            </ul>
          </div>

          <div class="col-md-6 col-xl-4 py-1">
          <p className='text-uppercase text-black mb-2'><strong><small>About</small></strong></p>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><Link to="/contact" class="nav-link p-0 text-muted" activeClassName="nav-link p-0 text-muted">Contact Us</Link></li>
              <li class="nav-item mb-2"><Link class="nav-link p-0 text-muted" activeClassName="nav-link p-0 text-muted" to="/about/company-information">Company Information</Link></li>
              <li class="nav-item mb-2"><Link to="/about/trademarks" class="nav-link p-0 text-muted" activeClassName="nav-link p-0 text-muted" >Trademarks</Link></li>
              <li class="nav-item mb-2"><Link to="/about/your-data" class="nav-link p-0 text-muted" activeClassName="nav-link p-0 text-muted" >Your Data</Link></li>
            </ul>
          </div>

          <div class="col-md-12 col-xl-4 py-1 order-xl-first order-last">
            <p class="text-muted"><small>&copy; {currentYear} Redacted Shiny Ltd</small></p>
          </div>
        </div>
      </div>
    </footer>
  )
}
// Step 3: Export your component

import * as React from 'react'

import logo from '../logo.svg'
import { Link } from 'gatsby'

// Step 2: Define your component

const Logo = (props) => {
  return <img class="bi me-2" src={logo} alt="Redacted Shiny Ltd logo" style={{ height: "2.5rem" }} />
}

export const Header = ({containerClasses}) => {
  return (
    <header class="border-bottom pad-safe-lr">
      <div class={containerClasses}>
        <div class="row  py-2">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between">
          <Link to="/" class="" >
            <Logo />
          </Link>
          <ul class="nav col-12 col-sm-auto mb-2 justify-content-center mb-sm-0">
            <li><Link to="/" class="nav-link px-2 link-secondary" activeClassName="nav-link px-2 link-secondary">Home</Link></li>
            <li><Link to="/services" class="nav-link px-2 link-secondary" activeClassName="nav-link px-2 link-secondary">Services</Link></li>
            <li><Link to="/contact" class="nav-link px-2 link-secondary" activeClassName="nav-link px-2 link-secondary"><strong>Contact Us</strong></Link></li>
          </ul>
        </div>
        </div>
      </div>
    </header>
  )
}
// Step 3: Export your component

import * as React from 'react'

export default function Grouping(props){
    return (
        <div class="row border-top py-3">
            <div class="col-md-4">
                <h2 class="mt-0 h4">{props.header}</h2>
                <p><strong>{props.subhead}</strong></p>
            </div>
            <div class="col col-md-8 rslist">
                {props.children}
            </div>
        </div>
    )
}

